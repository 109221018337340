<template>
    <div>
        <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            top="2vh"
            style="margin-bottom:1vh"
            width="1000px">
            <el-form v-if="showType == 1" ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                <el-form-item label="原设备编号" prop="deviceCode">
                    <el-input v-model="form.deviceCode" :disabled="originDevDisable" maxlength="20" placeholder="请输入" style="width:400px"></el-input>
                </el-form-item>
                <el-form-item label="目标设备编号" prop="targetDeviceCode">
                    <div class="a-flex-cfsfs">
                        <span>{{ form.targetDeviceCode }}</span>
                        <span class="a-c-error">{{ form.deviceType == 6 ? '当前操作为“向目标设备追加权限”，而非覆盖目标设备权限！！！' : '' }}</span>
                    </div>
                </el-form-item>
                <el-form-item label="覆盖类型" prop="type">
                    <el-select v-model="form.type" :disabled="originDevDisable" placeholder="请选择" style="width:400px">
                        <el-option v-for="item in authCopyTypeDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="line" v-if="showType == 2">
                已成功导入权限(权限操作耗时较大，请手动刷新结果)
                <el-button type="primary" @click="refreshTableData">刷新</el-button>
            </div>
            <le-pagview class="a-mt-10" v-if="showType == 2" ref="devAuthSetPage" @setData="setTableData" :pageParam="pageParam" :isFixed="false">
                <el-table 
                    ref="devAuthSetList"
                    :data="tableData" 
                    :highlight-current-row="true" 
                    height="50vh"
                    style="width: 100%">
                    <el-table-column prop="createTimeText" label="卡号" min-width="170">
                        <template slot-scope="{ row }">
                            <span>{{ row.cardNo?row.cardNo:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fileName" label="开始时间" min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.startTime?row.startTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startTime" label="结束时间" min-width="330">
                        <template slot-scope="{ row }">
                            <span>{{ row.endTime?row.endTime:'-' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                showType: 1,
                form: {
					deviceCode: '',
					targetDeviceCode: '',
					deviceType: '',
					type: '',
                },
                rules: {
                    deviceCode: [{required: true, message:'请输入原设备编号', trigger: 'blur'}],
                    type: [{required: true, message:'请选择覆盖类型', trigger: 'change'}],
                },
                authCopyTypeDic: [],
                pageParam: {
                    url: this.$Config.apiUrl.getDeviceCardPremission,
                    method: "post",
                    params: {
                        deviceCode: '',// 设备码
                    },
                    freshCtrl: -1,
                },
                tableData: []
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
                if(!val){
                    this.form = {
                        deviceCode: '',
                        targetDeviceCode: '',
                        deviceType: '',
                        type: ''
                    }
                }
                this.showType = 1
            },
        },
        props: {
            originDevDisable:{
                default: false
            },
            title:{
                default: '设备数据覆盖'
            }
        },
        created () {
            this.$getDic('authCopyType','select').then(res=>{ this.authCopyTypeDic = res; })
        },
        methods:{
            setTableData (datas) {
                this.tableData = datas
            },
            refreshTableData () {
                this.pageParam.freshCtrl++
            },
            submit () {
                if(this.showType == 2){
                    this.dialogVisible = false
                    return
                }
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$confirm('是否确认提交？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.recordDeviceTransplant,
                                method: "post",
                                params: {
                                    ...this.form,
                                },
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.pageParam.params.deviceCode = this.form.targetDeviceCode
                                    this.pageParam.freshCtrl++
                                    this.showType = 2
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        }).catch(_=>{ })
                    }
                })
                
            },
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        // width: 10px !important;
        height: 10px !important;
    }
    .line{
        font-size: 14px;
        font-weight: 700;
        color: #333;
        // border-top: 1px dashed #C0C4CC;
        // padding-top: 20px;
    }
</style>