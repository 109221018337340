<template>
    <div>
        <el-dialog
            title="远程开电"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                <el-form-item label="开电时长" prop="chargingMinutes" v-if="chargePlan.type != 6 && chargePlan.type != 8 && chargePlan.type != 10 && chargePlan.type != 11">
                    <el-input v-model="hours" maxlength="11" placeholder="请输入小时" @input="hours = util.checkIntNum(hours)" style="width: 100px"></el-input>
                    <span style="padding: 0 12px">小时</span>
                    <el-input v-model="minutes" maxlength="11" placeholder="请输入分钟" @input="minutes = util.checkIntNum(minutes)" style="width: 100px"></el-input>
                    <span style="padding: 0 12px">分钟</span>
                </el-form-item>
                <el-form-item label="充电额度" prop="chargeLimit" v-else>
                    <el-select v-model="form.chargeLimit" placeholder="请选择充电额度" clearable v-if="deviceInfo">
                        <el-option v-for="(item,index) in pickerRange" :key="index"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户选择" prop="phone">
                    <el-input v-model="form.phone" maxlength="11" placeholder="请输入手机号" clearable style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="插座选择" prop="deviceOutLetNumber">
                    <el-select v-model="form.deviceOutLetNumber" placeholder="请选择插座" clearable v-if="deviceInfo">
                        <el-option v-for="(val,key,index) in deviceInfo.slotUseStatus" :key="index"
                            :label="deviceInfo.slotNum<=2 ? key==1 ? '左侧插座' : '右侧插座' : key + '号插座'"
                            :value="key"
                            :disabled="val?true:false">
                        </el-option>
                    </el-select>
                    
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        <pay-dialog ref="payDialog" :isWxPay="false"></pay-dialog>
    </div>
</template>

<script>
    import util from '../../../utils/util';
    import payDialog from '../../components/poppup/pay-dialog.vue';
    export default {
        components: { payDialog },
        data() {
            var checkPhone = (rule, value, callback) => {
                let reg = RegExp('^[1][3,4,5,6,7,8,9][0-9]{9}$');
                if (reg.test(value)) {
                    callback();
                } else {
                    callback(new Error('手机号格式不正确'));
                }
            };
            var validateTimes = (rule, value, callback) => {
                if (!this.form.chargingMinutes) {
                    callback(new Error('请输入充电时长'));
                } else {
                    callback();
                }
            };
            
            return {
                util: util,
                dialogVisible: false,
                orderTypeDic: {
                    1:2,
                    4:4,
                    5:5
                },
                form: {
                    deviceOutLetNumber: null,
                    chargingMinutes: '',// 开电时长
                    phone: '',
                    chargeLimit: '',//
                },
                rules: {
                    chargingMinutes: [{required: true, message:'请输入充电时长', trigger: 'blur'},{ validator: validateTimes, trigger: 'blur' }],
                    phone: [{required: true, message:'请输入用户手机号', trigger: 'blur'},{ validator: checkPhone, trigger: 'blur' }],
                    deviceOutLetNumber: [{required: true, message:'请选择充电插座/充电仓口', trigger: 'change'}],
                    chargeLimit: [{required: true, message:'请选择充电额度', trigger: 'change'}]
                },
                hours: '',
                minutes: '',
                chargePlan: {
                    type: ''
                },
                pickerRange: []
            };
        },
        props:{
            deviceInfo:{
                type: Object
            }
        },  
        computed: {
            chargingMinutess () {
                return ((this.hours?Number(this.hours):0) * 60) + (this.minutes?Number(this.minutes):0)
            }
        },
        watch:{
            dialogVisible (val) {
                this.hours = ''
                this.minutes = ''
                this.form.chargingMinutes = ''
                this.form.phone = ''
                if(val){
                    if(this.deviceInfo.selectSlotNo){
                        this.$set(this.form,'deviceOutLetNumber',this.deviceInfo.selectSlotNo?this.deviceInfo.selectSlotNo:'')
                    }else{
                        for (const key in this.deviceInfo.slotUseStatus) {
                            const element = this.deviceInfo.slotUseStatus[key];
                            if(element<0 || !element){
                                this.$set(this.form,'deviceOutLetNumber',key)
                                break
                            }
                        }
                    }
                }
                if(this.$refs['form'] && !val) this.$refs['form'].clearValidate()
            },
            deviceInfo:{
                deep: true,
                handler (val) {
                    this.$nextTick(()=>{
                        this.$set(this.form,'deviceOutLetNumber',val.selectSlotNo?val.selectSlotNo:'')
                        this.$set(this.form,'deviceCode',val.deviceCode)
                        this.getChargePlan()
                    })
                }
            },
            chargingMinutess (val) {
                this.$set(this.form, 'chargingMinutes', val)
            }
        },
        methods:{
            // 获取定价
            getChargePlan () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getDeviceChargePlan,
                    method: "get",
                    params: {
                        deviceCode: this.form.deviceCode
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.chargePlan = res.result.data
                        if(this.chargePlan.type == 5) {
                            this.setPickerRange(
                                Number(this.chargePlan.lowChoice),
                                Number(this.chargePlan.highChoice),
                                Number(this.chargePlan.stepChoice),
                                '分钟'
                            );
                        }else if(this.chargePlan.type == 6) {
                            this.setPickerRange(
                                this.chargePlan.baseAmount,
                                1200,
                                this.chargePlan.stepAmount,
                                '元'
                            );
                        }else if(this.chargePlan.type == 8) {
                            this.setPickerRange(
                                this.chargePlan.baseAmount,
                                1200,
                                this.chargePlan.stepAmount,
                                '元'
                            );
                        }else if(this.chargePlan.type == 10) {
                            this.setPickerRange(
                                Number(this.chargePlan.lowChoice),
                                Number(this.chargePlan.highChoice),
                                Number(this.chargePlan.stepChoice),
                                '元'
                            );
                        }else if(this.chargePlan.type == 11) {
                            this.setPickerRange(
                                Number(this.chargePlan.lowChoice),
                                Number(this.chargePlan.highChoice),
                                Number(this.chargePlan.stepChoice),
                                'kwh'
                            );
                        }
                    }
                })
                .catch(err=>{

                }) 
            },
            setPickerRange (min, max, step, unit) {
                let range = []
                for (let i = Number(min); i <= Number(max); i = this.$decimal.decimal_add(i, step)) {
                    let label
                    if(unit == '元') {
                        label = this.$decimal.decimal_except(i, 100) + unit
                    }else {
                        label = i + unit
                    }
					range.push({
						label: label,
						value: i,
					});
				}
				this.pickerRange = range
            },
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$Axios._get({
                            url: this.$Config.apiUrl.getUserByPhone,
                            method: "get",
                            params: {
                                phone: this.form.phone
                            }
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                let reqData = {
                                    userId: res.result.data.id,
                                    expectEnergy: this.chargePlan.type == 11 ? this.form.chargeLimit : '',
                                    orderAmount: this.chargePlan.type == 6 || this.chargePlan.type == 8 || this.chargePlan.type == 10 ? this.form.chargeLimit : '',
                                    ...this.form
                                }
                                this.createOrder(reqData)
                            }
                        })
                        .catch(err=>{

                        }) 
                    }
                })
                
            },
            createOrder (reqData) {
                this.$confirm('是否确认远程开启充电？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    reqData.deviceOutLetNumber = Number(reqData.deviceOutLetNumber) - 1
                    this.$Axios._post({
                        url: this.$Config.apiUrl.superPlaceOrder,
                        method: "post",
                        params: {
                            orderType: this.orderTypeDic[this.deviceInfo.deviceCategroy] || 2, //订单类型：充电，套餐，实体卡，钱包充值，在线卡余额充值等
                            orderParams: {
                                ...reqData
                            }
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$refs['payDialog'].dialogVisible = true
                            this.$refs['payDialog'].payParams = {
                                orderId: res.result.data.orderId,
                                payAmount: res.result.data.payAmount,
                                orderType: res.result.data.orderType,
                            }
                            this.dialogVisible = false
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                }).catch(_=>{ })
            }
        }
    }
</script>