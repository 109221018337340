<template>
    <div>
        <le-pagview ref="devOnlineListPage" @setData="setTableData" :pageParam="pageParam">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false">
                <le-date-range ref="dateRange" label="" :minDate.sync="pageParam.params.startTime" :maxDate.sync="pageParam.params.endTime" />
            </le-search-form>
            <el-table :data="tabledata" ref="onlinelist" @filter-change='filterFun'>
                <el-table-column 
                    width="128" 
                    :label="pageParam.params.opType | initTablelabel(opTypeDic,'操作类型')" 
                    prop="typeText" 
                    column-key="opType"
                    :filter-multiple='false' 
                    :filters="opTypeDic">
                    <template slot-scope="{ row }">
                        <span>{{ row.type | initDic(opTypeDic) }}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="200" label="在线/离线时间" prop="createTimeText"></el-table-column>
            </el-table>
        </le-pagview>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageParam:{
                    url: this.$Config.apiUrl.getDeviceOnlineRecord,
                    method: "post",
                    params: {
                        deviceCode: this.deviceCode,// 设备码
                        startTime: '',
                        endTime: '',
                        type: '',// 操作类型
                    },
                    freshCtrl: 1,
                },
                tabledata:[],
                opTypeDic: [],//操作类型字典
            }
        },
        props:['deviceCode'],
        computed:{
            queryTime () {
                return this.pageParam.params.startTime + this.pageParam.params.endTime
            }
        },
        watch:{
            // queryTime (newVal, oldVal) {
            //     if(newVal != oldVal) {
            //         this.handlerSearch()
            //     }
            // }
        },
        created () {
            this.pageParam.params.startTime = this.$getDay.getTodayBeforeDays(6)
            this.pageParam.params.endTime = this.$getDay.getToday()
            this.$getDic('onlineType').then(res=>{ this.opTypeDic = res; })
        },  
        methods:{
            setTableData (datas) {
                this.tabledata = datas
            },
            handlerRest() {
                this.pageParam.params = {
                    deviceCode: this.deviceCode,// 设备码
                    type: '',
                    startTime: '',
                    endTime: '',
                };
                this.$set(this.pageParam.params,'startTime',this.$getDay.getTodayBeforeDays(6))
                this.$set(this.pageParam.params,'endTime',this.$getDay.getToday())
                this.$refs['dateRange'].value1 = [this.$getDay.getTodayBeforeDays(6),this.$getDay.getToday()]
                this.$refs['onlinelist'].clearFilter()
                
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs.devOnlineListPage.pageNum = 1
                this.pageParam.freshCtrl++;
            },
            filterFun (value) {
                for(var key in value){
                    if(key=='opType'){
                        if(value[key].length==0){
                            this.pageParam.params.type = ''
                        }else{
                            this.pageParam.params.type = value[key][0]
                        }
                    }
                }
                this.$refs['devOnlineListPage'].pageNum = 1;
                this.pageParam.freshCtrl++;
            }
        }
    }
</script>