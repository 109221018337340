<template>
    <div>
        <el-dialog
            title="支付结果"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            center
            width="500px">
            <div class="a-flex-cfsc" v-if="payResult == 'waiting'">
                <i class="el-icon-loading a-c-blue" style="font-size: 60px"></i>
                <span class="a-mt-20">正在等待支付结果</span>
            </div>
            <div class="a-flex-cfsc" v-if="payResult == 'success'">
                <i class="el-icon-success a-c-blue" style="font-size: 60px"></i>
                <span class="a-mt-20">支付成功</span>
            </div>
            <div class="a-flex-cfsc" v-if="payResult == 'fail'">
                <i class="el-icon-error a-c-red" style="font-size: 60px"></i>
                <span class="a-mt-20">支付失败</span>
            </div>
            <div class="a-flex-cfsc" v-if="payResult == 'timeout'">
                <i class="el-icon-warning a-c-warning" style="font-size: 60px"></i>
                <span class="a-mt-20">支付结果等待超时</span>
            </div>
            <div slot="footer">
                <el-button class="plainBtn" v-if="payParams.orderType == 1" @click="toPackageOrderList">套餐订单</el-button>
                <el-button type="primary" @click="confirm">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    export default {
        data() {
            return {
                dialogVisible: false,
                payResult: 'waiting',
                payResultInterval: null,
                payResultTimeout: null
            };
        },
        props: ['payParams'],
        watch:{
            dialogVisible (val) {
                if(val){
                    this.payResult = 'waiting'
                    clearInterval(this.payResultInterval)
                    clearTimeout(this.payResultTimeout)
                    this.getPayResult()
					setTimeout(()=>{
                        if(this.payResult == 'waiting'){
						    this.payResult = 'timeout'
                        }
						clearInterval(this.payResultInterval)
                        clearTimeout(this.payResultTimeout)
					},60 * 1000 * 1)
					this.payResultInterval = setInterval(()=>{
						if(this.payResult == 'waiting'){
							this.getPayResult()
						}else{
							clearInterval(this.payResultInterval)
                            clearTimeout(this.payResultTimeout)
						}
					}, 2000)
                }else{
                    this.getCompanyInfo()
                }
            },
        },
        
        methods:{
            ...mapActions({
                getCompanyInfo: 'company/getCompanyInfo'
            }),
            getPayStatus () {
                this.payResult = 'waiting'
                clearInterval(this.payResultInterval)
                clearTimeout(this.payResultTimeout)
                this.getPayResult()
                setTimeout(()=>{
                    if(this.payResult == 'waiting'){
                        this.payResult = 'timeout'
                    }
                    clearInterval(this.payResultInterval)
                    clearTimeout(this.payResultTimeout)
                },60 * 1000 * 15)
                this.payResultInterval = setInterval(()=>{
                    if(this.payResult == 'waiting'){
                        this.getPayResult()
                    }else{
                        this.dialogVisible = true
                        this.$emit('finish',true)
                        clearInterval(this.payResultInterval)
                        clearTimeout(this.payResultTimeout)
                    }
                }, 2000)
            },
            getPayResult () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getPayResult,
                    method: "get",
                    showLoading: false,
                    params: {
                        orderId: this.payParams.orderId,
                        orderType: this.payParams.orderType,
                    },
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        if(this.payResult != 'timeout'){
                            if(res.result.data == 1){
                                this.payResult = 'success'
                            }else if(res.result.data === 0){
                                this.payResult = 'waiting'
                            }else{
                                this.payResult = 'fail'
                            }
                        }
                    } else {
                        if(this.payResult != 'timeout'){
                            this.payResult = 'fail'
                        }
                    }
                })
                .catch(err=>{
                    if(this.payResult != 'timeout'){
                        this.payResult = 'fail'
                    }
                })
            },
            toPackageOrderList () {
                this.dialogVisible = false
                clearInterval(this.payResultInterval)
                clearTimeout(this.payResultTimeout)
                this.$router.push({
                    path:'/order/package-order-list',
                })
            },
            confirm () {
                this.dialogVisible = false
                clearInterval(this.payResultInterval)
                clearTimeout(this.payResultTimeout)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .payQRcode{
        width: 120px;
        height: 120px;
    }
</style>