<template>
    <div>
        <el-dialog
            title="设备检测结果"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-table :data="tableData">
                    <el-table-column label="插槽" prop="typeText">
                        <template slot-scope="{ row }">
                            <span>{{ Number(row.slot) + 1 }}号插槽</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="电流" prop="content">
                        <template slot-scope="{ row }">
                            <span>{{ row.current }}A</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="功率" prop="orderId">
                        <template slot-scope="{ row }">
                            <span>{{ row.power }}W</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="门状态" prop="orderId" v-if="deviceInfo && (deviceInfo.deviceCategroy == 4 || deviceInfo.deviceCategroy == 5)">
                        <template slot-scope="{ row }">
                            <span>{{ row.doorStatus == 1 ? '关闭' : '开启' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer">
                <el-button type="primary"  @click="submit">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                tableData: []
            };
        },
        props: ['deviceInfo'],
        methods:{
            submit () {
                this.dialogVisible = false
            },

        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>