<template>
    <div>
        <el-dialog
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            :before-close="closePay"
            center
            top="10vh"
            width="500px">
            <div slot="title">
                <span>订单支付金额</span>
                <span class="a-fw-700 a-fs-20 a-plr-7">{{ payParams.payAmount?Number((payParams.payAmount/100).toFixed(2)):'-' }}</span>
                <span>元</span>
            </div>
            <div class="a-flex-rsac a-mb-05" v-if="(userInfo.dataPermission == 99 || userInfo.dataPermission == 88) && payParams.orderType != 4 && payParams.orderType != 5">
                <el-radio-group v-model="payMode">
                    <el-radio :label="1">普通支付</el-radio>
                    <el-radio :label="2">赠与</el-radio>
                </el-radio-group>
            </div>
            <div v-if="payMode == 1">
                <el-form ref="form" label-position="left" label-width="400px" >
                    <el-form-item :label="'钱包（余额￥' + (company&&company.balance?Number((company.balance/100).toFixed(2)):'-') + '）'" prop="expireTime">
                        <div class="a-flex-rfec">
                            <i class="el-icon-success a-fs-20" :class="payType=='7'?'a-c-blue':'a-c-second'" @click="handlerChangePayType('7')"></i>
                        </div>
                    </el-form-item>
                    <el-form-item prop="day" v-if="company.creditbalance || company.creditbalance===0">
                        <div slot="label">
                            <span>毛豆赊款</span>
                            <el-popover
                                placement="bottom-start"
                                title=""
                                width="400"
                                trigger="hover">
                                <div class="a-flex-cfsfs">
                                    <span>毛豆赊是平台为商户提供的先享用，后付款的专项权益，
                                        可以简单理解为免费的赊账功能 .目前在试运行阶段只
                                        有余额不足时才可使用毛豆赊的额度。 您当前的总额度
                                        是￥{{ (company&&company.creditTotal?Number((company.creditTotal/100).toFixed(2)):'-') }}
                                        可用额度为￥{{ (company&&company.creditbalance?Number((company.creditbalance/100).toFixed(2)):'-') }}。</span>
                                </div>
                                <span slot="reference" class="el-icon-info a-c-333" style="margin-left: 5px;"></span>
                            </el-popover>
                            <span>（额度剩余￥{{ (company&&company.creditbalance?Number((company.creditbalance/100).toFixed(2)):'-') }}）</span>
                        </div>
                        <div class="a-flex-rfec">
                            <i class="el-icon-success a-fs-20" :class="payType=='8'?'a-c-blue':'a-c-second'" @click="handlerChangePayType('8')"></i>
                        </div>
                    </el-form-item>
                    <el-form-item label="二维码扫码支付" prop="day" v-if="isWxPay">
                        <div class="a-flex-rfec">
                            <i class="el-icon-success a-fs-20" :class="payType=='9'?'a-c-blue':'a-c-second'" @click="handlerChangePayType('9')"></i>
                        </div>
                    </el-form-item>
                </el-form>
                <div class="a-flex-cfsc" v-show="showPayQrcode">
                    <div class="payQRcode" id="payQRcode"></div>
                    <span class="a-mt-16" v-if="countDown>0">{{ countDown | initCountDown }} 后失效</span>
                    <span class="a-mt-16" v-else>二维码已失效，请点击刷新重新获取二维码</span>
                </div>
                <div slot="footer" v-if="payType != 9" class="a-flex-rcc">
                    <el-button class="plainBtn" @click="recharge">钱包充值</el-button>
                    <el-button type="primary" @click="submitPay">确认支付</el-button>
                </div>
                <div slot="footer" v-else class="a-flex-rcc">
                    <el-button class="plainBtn" @click="getPayUrl">刷新</el-button>
                    <el-button type="primary" @click="getPayResult">我已支付</el-button>
                </div>
            </div>
            <div v-if="payMode == 2" class="a-mt-15">
                <el-form ref="form2" label-position="left" label-width="100px" >
                    <el-form-item label="赠与原因" prop="giveReason">
                        <el-input
                            type="textarea"
                            :rows="6"
                            maxlength="120"
                            placeholder="请输入内容"
                            v-model="form2.giveReason">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="a-flex-rcc">
                    <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="submit">确认</el-button>
                </div>
            </div>
        </el-dialog>
        <pay-success ref="paySuccess" :payParams="payParams" @finish="dialogVisible = false"></pay-success>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import paySuccess from './pay-success.vue';
    export default {
    components: { paySuccess },
        data() {
            return {
                dialogVisible: false,
                payType: '',
                showPayQrcode: false,// 是否限制支付二维码
                payUrlQrcode: '',
                countDown: 0,//倒计时
                payParams: {},//支付参数
                timeInterval: null,
                payMode: 1,
                form2: {
                    giveReason: ''
                },
            };
        },
        props: {
            isWxPay:{
                default: true
            }
        },
        watch:{
            dialogVisible (val) {
                if(val){
                    this.payMode = 1
                    this.payType = '7'
                    this.form2.giveReason = ''
                }else{
                    this.$nextTick(()=>{
                        this.showPayQrcode = false
                        this.payUrlQrcode = ''
                        this.countDown = ''
                        clearInterval(this.timeInterval)
                    })
                }
            },
        },
        computed:{
            ...mapState({
                company: state => state.company.company,
                userInfo: state => state.user.user,
            })
        },
        filters: {
            initCountDown (val) {
                let munite = parseInt(val / 60)
                let second = parseInt(val % 60)
                return (munite?munite<10?'0'+munite:munite:'00') + ':' + (second?second<10?'0'+second:second:'00')
            }
        },
        methods:{
            handlerChangePayType (type) {
                this.payType = type
                if(type == 9){
                    if(this.payUrlQrcode){
                        this.showPayQrcode = true
                    }else{
                        this.getPayUrl()
                    }
                }
            },
            // 支付二维码倒计时
            countDownFun () {
                this.timeInterval = setInterval(()=>{
                    if (this.countDown <= 0){
                        clearInterval(this.timeInterval)
                    }else{
                        this.countDown--
                    }
                },1000)
            },
            // 获取支付二维码链接
            getPayUrl () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.payOrder,
                    method: "post",
                    showLoading: true,
                    params: {
                        ...this.payParams,
                        payChannel: this.payType,
                    },
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.showPayQrcode = true
                        this.payUrlQrcode = res.result.data.qrCode
                        jQuery('#payQRcode').html('')
                        jQuery('#payQRcode').qrcode({   
                            render: "canvas",
                            text: this.payUrlQrcode,
                            width: 120,
                            height: 120,
                            background: "#ffffff",//背景颜色  
                            foreground: "#000000", //前景颜色
                        });
                        this.countDown = 15 * 60
                        clearInterval(this.timeInterval)
                        this.countDownFun()
                        this.$refs['paySuccess'].getPayStatus()
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{
                })
            },
            closePay () {
                this.$confirm('是否确认关闭支付？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.dialogVisible = false
                }).catch(_=>{ })
            },
            submitPay () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.payOrder,
                    method: "post",
                    params: {
                        ...this.payParams,
                        payChannel: this.payType,
                    },
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.dialogVisible = false
                        this.$refs['paySuccess'].dialogVisible = true
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            },
            getPayResult () {
                this.dialogVisible = false
                this.$refs['paySuccess'].dialogVisible = true
            },
            recharge () {
                this.$confirm('充值请联系您的商务对接人员', '温馨提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                    showCancelButton: false,
                }).then(_=>{})
            },
            // 确认赠与
            submit () {
                if(!this.form2.giveReason){
                    this.$message.error('请输入赠予原因')
                    return
                }
                this.$Axios._post({
                    url: this.$Config.apiUrl.payOrder,
                    method: "post",
                    params: {
                        ...this.payParams,
                        payChannel: 10,
                        giveReason: this.form2.giveReason
                    },
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.dialogVisible = false
                        this.$refs['paySuccess'].dialogVisible = true
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .payQRcode{
        width: 120px;
        height: 120px;
    }
</style>