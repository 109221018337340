<template>
    <div>
        <le-pagview ref="bindlist" @setData="setTableData" :pageParam="pageParam">
            <el-table :data="tabledata" @filter-change='filterFun'>
                <el-table-column 
                    :label="pageParam.params.opType | initTablelabel(opTypeDic,'操作类型')"
                    prop="typeText" 
                    column-key="opType"
                    :filter-multiple='false'
                    :filters="opTypeDic"></el-table-column>
                <el-table-column label="所属站点" prop="stationName"></el-table-column>
                <el-table-column label="绑定/解绑途径" prop="appIdText"></el-table-column>
                <el-table-column label="操作人" prop="operatorUserName"></el-table-column>
                <el-table-column label="操作时间" prop="createTimeText"></el-table-column>
            </el-table>
        </le-pagview>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageParam:{
                    url: this.$Config.apiUrl.getDeviceOptionTimeline,
                    method: "post",
                    params: {
                        deviceCode: this.deviceCode,// 设备码
                        type: '',//操作类型
                    },
                    freshCtrl: 1,
                },
                tabledata: [],
                opTypeDic: [],//操作类型
            }
        },
        props:['deviceCode'],
        created () {
            this.$getDic('deviceBindType').then(res=>{ this.opTypeDic = res; })
        },
        methods:{
            setTableData (datas) {
                this.tabledata = datas
            },
            filterFun (value) {
                for(var key in value){
                    if(key=='opType'){
                        if(value[key].length==0){
                            this.pageParam.params.type = ''
                        }else{
                            this.pageParam.params.type = value[key][0]
                        }
                    }
                }
                this.$refs['bindlist'].pageNum = 1;
                this.pageParam.freshCtrl++;
            }
        }
    }
</script>