<template>
    <div>
        <el-dialog
            title="远程开门开电"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                <el-form-item label="操作类型">
                    <el-radio-group v-model="form.operate">
                        <el-radio :label="1">仅开门</el-radio>
                        <el-radio :label="2">仅开电</el-radio>
                        <el-radio :label="3">开门开电</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div v-if="(form.operate != 1 && (deviceInfo.deviceType == 9 || deviceInfo.deviceType == 12)) || (form.operate == 3 && deviceInfo.deviceType == 8)">
                    <el-form-item label="开电时长" prop="chargingMinutes" v-if="chargePlan.type != 6 && chargePlan.type != 8 && chargePlan.type != 10 && chargePlan.type != 11">
                        <el-input v-model="hours" maxlength="11" placeholder="请输入小时" @input="hours = util.checkIntNum(hours)" style="width: 100px"></el-input>
                        <span style="padding: 0 12px">小时</span>
                        <el-input v-model="minutes" maxlength="11" placeholder="请输入分钟" @input="minutes = util.checkIntNum(minutes)" style="width: 100px"></el-input>
                        <span style="padding: 0 12px">分钟</span>
                    </el-form-item>
                    <el-form-item label="充电额度" prop="chargeLimit" v-else>
                        <el-select v-model="form.chargeLimit" placeholder="请选择充电额度" clearable v-if="deviceInfo">
                            <el-option v-for="(item,index) in pickerRange" :key="index"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <el-form-item label="用户选择" prop="phone" v-if="form.operate != 1">
                    <el-input v-model="form.phone" maxlength="11" placeholder="请输入手机号" clearable style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="订单号" prop="orderId" v-if="form.operate != 1">
                    <div class="a-flex-ccfs">
                        <el-radio-group v-model="form.placeOrderType" style="height: 34px" class="a-flex-rfsc">
                            <el-radio :label="1" v-if="userInfo.dataPermission == 99" :disabled="deviceInfo.deviceType == 8 && form.operate == 2">创建新订单</el-radio>
                            <el-radio :label="2">使用已有订单</el-radio>
                        </el-radio-group>
                        <div class="a-flex-rfsc" v-if="form.placeOrderType == 2">
                            <el-select v-model="form.orderId" placeholder="请选择订单" style="width: 240px;margin-right: 10px;">
                                <el-option v-for="(item,index) in orderList" :key="index"
                                    :label="item.orderSn"
                                    :value="item.orderSn">
                                </el-option>
                            </el-select>
                            <el-button type="primary" @click="queryOrder">查询进行中的订单</el-button>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="仓口选择" prop="deviceOutLetNumber">
                    <el-select v-model="form.deviceOutLetNumber" placeholder="请选择仓口" clearable v-if="deviceInfo">
                        <el-option v-for="(val,key,index) in deviceInfo.slotUseStatus" :key="index"
                            :label="key + '号仓口'"
                            :value="key"
                            :disabled="form.placeOrderType == 2 && form.operate != 1">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        <pay-dialog ref="payDialog" :isWxPay="false"></pay-dialog>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import util from '../../../utils/util';
    import payDialog from '../../components/poppup/pay-dialog.vue';
    export default {
        components: { payDialog },
        data() {
            var checkPhone = (rule, value, callback) => {
                let reg = RegExp('^[1][3,4,5,6,7,8,9][0-9]{9}$');
                if (reg.test(value)) {
                    callback();
                } else {
                    callback(new Error('手机号格式不正确'));
                }
            };
            var validateTimes = (rule, value, callback) => {
                if (!this.form.chargingMinutes) {
                    callback(new Error('请输入充电时长'));
                } else {
                    callback();
                }
            };
            
            return {
                util: util,
                dialogVisible: false,
                orderTypeDic: {
                    1:2,
                    4:4,
                    5:5
                },
                form: {
                    operate: 1,
                    deviceOutLetNumber: null,
                    chargingMinutes: '',// 开电时长
                    phone: '',
                    orderId: '',
                    placeOrderType: 1,
                    chargeLimit: '',//
                },
                rules: {
                    chargingMinutes: [{required: true, message:'请输入充电时长', trigger: 'blur'},{ validator: validateTimes, trigger: 'blur' }],
                    phone: [{required: true, message:'请输入用户手机号', trigger: 'blur'},{ validator: checkPhone, trigger: 'blur' }],
                    deviceOutLetNumber: [{required: true, message:'请选择充电插座/充电仓口', trigger: 'change'}],
                    chargeLimit: [{required: true, message:'请选择充电额度', trigger: 'change'}]
                },
                hours: '',
                minutes: '',
                orderList: [],
                chargePlan: {
                    type: ''
                },
                pickerRange: []
            };
        },
        props:{
            deviceInfo:{
                type: Object
            }
        },  
        computed: {
            chargingMinutess () {
                return ((this.hours?Number(this.hours):0) * 60) + (this.minutes?Number(this.minutes):0)
            },
            ...mapState({
                company: state => state.company.company,
                userInfo: state => state.user.user,
            })
        },
        watch:{
            dialogVisible (val) {
                this.hours = ''
                this.minutes = ''
                this.form.chargingMinutes = ''
                this.form.phone = ''
                this.form.operate = 1
                this.form.orderId = ''
                this.form.placeOrderType = this.userInfo.dataPermission == 99?1:2  //暂时只有99权限的用户可以创建新订单
                if(val){
                    if(this.deviceInfo.selectSlotNo){
                        this.$set(this.form,'deviceOutLetNumber',this.deviceInfo.selectSlotNo?this.deviceInfo.selectSlotNo:'')
                    }else{
                        for (const key in this.deviceInfo.slotUseStatus) {
                            const element = this.deviceInfo.slotUseStatus[key];
                            if(element<0 || !element){
                                this.$set(this.form,'deviceOutLetNumber',key)
                                break
                            }
                        }
                    }
                }
                if(this.$refs['form'] && !val) this.$refs['form'].clearValidate()
            },
            deviceInfo:{
                deep: true,
                handler (val) {
                    this.$nextTick(()=>{
                        this.$set(this.form,'deviceOutLetNumber',val.selectSlotNo?val.selectSlotNo:'')
                        this.$set(this.form,'deviceCode',val.deviceCode)
                        this.$set(this.form,'imei',val.imei)
                        this.getChargePlan()
                    })
                }
            },
            chargingMinutess (val) {
                this.$set(this.form, 'chargingMinutes', val)
            },
            'form.orderId':{
                handler (val) {
                    let orderInfo = this.orderList.find(res=>{
                        return res.orderSn == val
                    })
                    if(orderInfo){
                        this.form.deviceOutLetNumber = orderInfo.boxId + 1
                        this.form.deviceOutLetNumber = JSON.stringify(this.form.deviceOutLetNumber)
                    }else{
                        this.form.deviceOutLetNumber = ''
                    }
                    console.log(this.form.deviceOutLetNumber);
                }
            },
            'form.operate': {
                handler (val) {
                    if(this.form.operate == 2){
                        this.form.placeOrderType = 2
                    }else{
                        this.form.placeOrderType = this.userInfo.dataPermission == 99?1:2  //暂时只有99权限的用户可以创建新订单
                    }
                    // this.$refs['form'].resetFields()
                }
            },
            'form.placeOrderType':{
                handler () {
                    this.form.orderId = ''
                }
            }
        },
        methods:{
            // 获取定价
            getChargePlan () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getDeviceChargePlan,
                    method: "get",
                    params: {
                        deviceCode: this.form.deviceCode
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.chargePlan = res.result.data
                        if(this.chargePlan.type == 5) {
                            this.setPickerRange(
                                Number(this.chargePlan.lowChoice),
                                Number(this.chargePlan.highChoice),
                                Number(this.chargePlan.stepChoice),
                                '分钟'
                            );
                        }else if(this.chargePlan.type == 6) {
                            this.setPickerRange(
                                this.chargePlan.baseAmount,
                                1200,
                                this.chargePlan.stepAmount,
                                '元'
                            );
                        }else if(this.chargePlan.type == 8) {
                            this.setPickerRange(
                                this.chargePlan.baseAmount,
                                1200,
                                this.chargePlan.stepAmount,
                                '元'
                            );
                        }else if(this.chargePlan.type == 10) {
                            this.setPickerRange(
                                Number(this.chargePlan.lowChoice),
                                Number(this.chargePlan.highChoice),
                                Number(this.chargePlan.stepChoice),
                                '元'
                            );
                        }else if(this.chargePlan.type == 11) {
                            this.setPickerRange(
                                Number(this.chargePlan.lowChoice),
                                Number(this.chargePlan.highChoice),
                                Number(this.chargePlan.stepChoice),
                                'kwh'
                            );
                        }
                    }
                })
                .catch(err=>{

                }) 
            },
            setPickerRange (min, max, step, unit) {
                let range = []
                for (let i = Number(min); i <= Number(max); i = this.$decimal.decimal_add(i, step)) {
                    let label
                    if(unit == '元') {
                        label = this.$decimal.decimal_except(i, 100) + unit
                    }else {
                        label = i + unit
                    }
					range.push({
						label: label,
						value: i,
					});
				}
				this.pickerRange = range
            },
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if(this.form.operate == 1){ //仅开门直接调用设备指令
                            let reqData = {
                                ...this.form
                            }
                            this.submitFun(reqData)
                            return
                        }
                        if(!this.form.orderId && this.form.placeOrderType == 2){
                            this.$message.error('当前用户无进行中的订单，无法延用订单。若仍要继续操作，请选择创建新订单。')
                            return
                        }
                        this.$Axios._get({
                            url: this.$Config.apiUrl.getUserByPhone,
                            method: "get",
                            params: {
                                phone: this.form.phone
                            }
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                let reqData = {
                                    userId: res.result.data.id,
                                    expectEnergy: this.chargePlan.type == 11 ? this.form.chargeLimit : '',
                                    orderAmount: this.chargePlan.type == 6 || this.chargePlan.type == 8 || this.chargePlan.type == 10 ? this.form.chargeLimit : '',
                                    ...this.form
                                }
                                if(this.form.placeOrderType == 1){
                                    this.createOrder(reqData)
                                }else if(this.form.placeOrderType == 2){
                                    this.submitFun(reqData)
                                }
                            }
                        })
                        .catch(err=>{

                        }) 
                    }
                })
                
            },
            submitFun (reqData) {
                let txt = {
                    1: '是否确定远程开门？',
                    2: '是否确定远程开电？',
                    3: '是否确定远程开门开电？'
                }
                let apiUrl = {
                    1: this.$Config.apiUrl.cabinetRemoteOpenOnly,
                    2: this.$Config.apiUrl.cabinetRemoteCharge,
                    3: this.$Config.apiUrl.cabinetRemoteOpen,
                }
                let reqDatas = ''
                if(this.form.operate == 1){
                    reqDatas = {
                        deviceOutLetNumber: reqData.deviceOutLetNumber,
                        imei: reqData.imei,
                        openDoor: 1,
                        orderId: reqData.orderId || ''
                    }
                }else if(this.form.operate == 2){
                    reqDatas = {
                        chargingMinutes: this.form.chargingMinutes,
                        deviceOutLetNumber: reqData.deviceOutLetNumber,
                        imei: reqData.imei,
                        openDoor: 0,
                        orderId: reqData.orderId || '',
                        expectEnergy: this.chargePlan.type == 11 ? this.form.chargeLimit : '',
						orderAmount: this.chargePlan.type == 6 || this.chargePlan.type == 8 || this.chargePlan.type == 10 ? this.form.chargeLimit : '',
                    }
                }else if(this.form.operate == 3){
                    reqDatas = {
                        chargingMinutes: this.form.chargingMinutes,
                        deviceOutLetNumber: reqData.deviceOutLetNumber,
                        imei: reqData.imei,
                        openDoor: 1,
                        orderId: reqData.orderId || '',
                        expectEnergy: this.chargePlan.type == 11 ? this.form.chargeLimit : '',
						orderAmount: this.chargePlan.type == 6 || this.chargePlan.type == 8 || this.chargePlan.type == 10 ? this.form.chargeLimit : '',
                    }
                }
                this.$confirm(txt[this.form.operate], '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    reqDatas.deviceOutLetNumber = Number(reqDatas.deviceOutLetNumber) - 1
                    this.$Axios._post({
                        url: apiUrl[this.form.operate],
                        method: "post",
                        params: {
                            ...reqDatas
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.dialogVisible = false
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                }).catch(_=>{ })
            },
            createOrder (reqData) {
                let txt = {
                    1: '是否确定远程开门？',
                    2: '是否确定远程开电？',
                    3: '是否确定远程开门开电？'
                }
                this.$confirm(txt[this.form.operate], '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    reqData.deviceOutLetNumber = Number(reqData.deviceOutLetNumber) - 1
                    this.$Axios._post({
                        url: this.$Config.apiUrl.superPlaceOrder,
                        method: "post",
                        params: {
                            orderType: this.orderTypeDic[this.deviceInfo.deviceCategroy] || 2, //订单类型：充电，套餐，实体卡，钱包充值，在线卡余额充值等
                            orderParams: {
                                ...reqData
                            }
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$refs['payDialog'].dialogVisible = true
                            this.$refs['payDialog'].payParams = {
                                orderId: res.result.data.orderId,
                                payAmount: res.result.data.payAmount,
                                orderType: res.result.data.orderType,
                            }
                            this.$nextTick(()=>{
                                this.$refs['payDialog'].payMode = 2
                            })
                            this.dialogVisible = false
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                }).catch(_=>{ })
            },
            queryOrder () {
                if(!this.form.phone){
                    this.$message.error('请先输入用户手机号')
                    return
                }
                this.$Axios._post({
                    url: this.$Config.apiUrl.cabinetOrderPage,
                    method: "post",
                    params: {
                        pageNum: 1,
                        pageSize: 20,
                        phone: this.form.phone,
                        deviceCode: this.form.deviceCode,
                        orderStatus: [1],// 查询进行中的订单
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        if(res.result.data.list && res.result.data.list.length){
                            this.orderList = res.result.data.list
                            this.form.orderId = this.orderList[0].orderSn
                        }
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            }
        }
    }
</script>