<template>
    <div>
        <el-dialog
            title="远程停止充电"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                <el-form-item label="设备号">
                    <span>{{ deviceInfo&&deviceInfo.deviceCode?deviceInfo.deviceCode:'-' }}</span>
                </el-form-item>
                <el-form-item label="插座/仓口" prop="deviceOutLetNumber" v-if="deviceInfo">
                    <el-select v-model="form.deviceOutLetNumber" placeholder="请选择插座/仓口" clearable>
                        <el-option v-for="(val,key,index) in deviceInfo.slotUseStatus" :key="index"
                            :label="deviceInfo.slotNum<=2 ? key==1 ? '左侧插座' : '右侧插座' : key + '号插座/仓口'"
                            :value="key">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import util from '../../../utils/util';
    export default {
        data() {
            return {
                util: util,
                dialogVisible: false,
                form: {
                    deviceOutLetNumber: null,
                },
                rules: {
                    deviceOutLetNumber: [{required: true, message:'请选择充电插座/充电仓口', trigger: 'change'}]
                },
            };
        },
        props:{
            deviceInfo:{
                type: Object
            }
        },  
        computed: {
            ...mapState({
                company: state => state.company.company,
                userInfo: state => state.user.user,
            })
        },
        watch:{
            dialogVisible (val) {
                this.form.deviceOutLetNumber = ''
                if(this.$refs['form'] && !val) this.$refs['form'].clearValidate()
            },
        },
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if(!this.form.deviceOutLetNumber){
                            this.$message.error('请选择插座/仓口')
                            return
                        }
                        this.$confirm(`是否确认远程停止充电？\n设备号：${this.deviceInfo.deviceCode}\n插座号/仓口号：${this.form.deviceOutLetNumber}`, '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.deviceStopCharge,
                                method: "post",
                                params: {
                                    imei : this.deviceInfo.imei,
                                    deviceOutLetNumber: Number(this.form.deviceOutLetNumber) - 1
                                }
                            }).then(res => {
                                if(res.result.code == 0){
                                    this.$message.success('操作成功')
                                    this.dialogVisible = false
                                    this.getDeviceInfo()
                                }else{
                                    this.$message.error(res.result.message)
                                }
                            }).catch(()=>{

                            })
                        }).catch(_=>{ })
                    }
                })
            }
        }
    }
</script>