<template>
    <div>
        <le-pagview ref="bindAuth" @setData="setTableData" :pageParam="pageParam">
            <div class="a-flex-rsbc">
                <le-search-form @reset="handlerRest" @search="handlerSearch" :showReset="false" :canPackUp="false">
                    <jd-select-input label="卡号" :maxlength="10" :value.sync="searchVal.value" :selectValue.sync="searchVal.select" :selectOptions="selectOptions"></jd-select-input>
                </le-search-form>
                <el-button type="primary" @click="devAuthSetFun" v-if="deviceType == 60 || deviceType == 10">设备权限优化</el-button>
            </div>
            <el-table :data="tabledata">
                <el-table-column label="卡号" prop="cardNo">
                    <template slot-scope="{ row }">
                        <span>{{ row.cardNo?row.cardNo:'-' }}（反序卡号：{{ row.cardNo?util.ox16Reverse(row.cardNo):'-' }}）</span>
                    </template>
                </el-table-column>
                <el-table-column label="手机号" prop="phone"></el-table-column>
                <el-table-column label="开始日期" prop="startTime"></el-table-column>
                <el-table-column label="结束日期" prop="endTime"></el-table-column>
            </el-table>
        </le-pagview>
        <dev-auth-set ref="devAuthSet" :originDevDisable="true" title="设备权限优化"></dev-auth-set>
    </div>
</template>

<script>
    import devAuthSet from './dev-auth-set.vue';
    import util from '../../../../src/utils/util'
    export default {
    components: { devAuthSet },
        data() {
            return {
                util: util,
                pageParam:{
                    url: this.$Config.apiUrl.getDeviceCardPremission,
                    method: "post",
                    params: {
                        deviceCode: this.deviceCode,// 设备码
                        cardNo: '',
                    },
                    freshCtrl: 1,
                },
                tabledata: [],
                searchVal: {
                    value: '',
                    select: '1'
                },
                selectOptions: [{
                    label: '正序',
                    value: '1'
                },{
                    label: '反序',
                    value: '2'
                }]
            }
        },
        props:['deviceCode','deviceType'],
        methods:{
            handlerRest() {
                this.pageParam.params = {
                    deviceCode: this.deviceCode,// 设备码
                    cardNo: ''
                };
                this.searchVal = {
                    value: '',
                    select: '1'
                }
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs.bindAuth.pageNum = 1
                if(this.searchVal.select == 2) {
                    this.pageParam.params.cardNo = this.searchVal.value?util.ox16Reverse(this.searchVal.value):''
                }else{
                    this.pageParam.params.cardNo = this.searchVal.value
                }
                this.pageParam.freshCtrl++;
            },
            setTableData (datas) {
                this.tabledata = datas?datas:[]
            },
            devAuthSetFun () {
                this.$refs['devAuthSet'].form.targetDeviceCode = this.deviceCode
                this.$refs['devAuthSet'].form.deviceCode = this.deviceCode
                this.$refs['devAuthSet'].form.deviceType = this.deviceType
                this.$refs['devAuthSet'].form.type = '0'
                this.$refs['devAuthSet'].dialogVisible = true
            }
        }
    }
</script>
<style lang="scss" scoped>
/deep/ .s-search-label{
    width: 40px !important;
    justify-content: flex-start !important;
}
</style>